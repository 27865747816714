<template>
  <div class="main">

    <h1 style="margin-left: 64px; margin-top: 32px; margin-bottom:20px;">{{$t('Identity.title')}}</h1>
    
    <!-- PANELS-->
    <div style="width: 75%;margin:auto;">
      <IdentityChoice :usr_id="getCurrentUsrId" :isApanel="false" :hide_title="true" :key="getCurrentUsrId" />
    </div>
   
    
  </div>
</template>

<script>


//STORE
import Vuex from "vuex"

//COMPONENTS
import IdentityChoice from "../../components/Panels/IdentityChoice/IdentityChoice.vue";


export default {
  components: {
    IdentityChoice
  },
  name: "Identity",

  data() {
    return {
      token: {}
    };
  },
  computed: {
    ...Vuex.mapGetters(["getCurrentUsrId"]),
  }

};
</script>

<style src="./style.css" scoped></style>