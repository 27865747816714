<template>
  <div class="identityModifier" ref="identityModifier">
    <div class="flexRow row1">
      <div class="name">
        <label>{{ $t("IdentityChoice.name") }}</label>
        <input class="charte_name" type="text" :placeholder="$t('IdentityChoice.myCharter')" ref="ident_name"
          @input="nameChanged" auto-complete="off" data-dashlane="nodashlane" data-lpignore="true"
          data-form-type="other" />
      </div>
    </div>

    <IdentityContent v-model="local_current_identity" :key="local_current_identity"></IdentityContent>
    <div class="flexRow row5">
      <!-- BUTTONS -->
      <button v-if="backButton" class="button identityActions" button-type="secondary" @click="$emit('finished')">
        {{ $t("Back") }}
      </button>

      <button class="button identityActions" button-type="secondary" :class="{ disabled: !addButtonActive }"
        v-if="menuMode.add == true" @click="add_ident()">
        <span v-if="waiting_for_api_response" class="loading_spinner center"
          style="--color : white; position: relative"></span>
        <a v-else>{{ $t("Add") }}</a>
      </button>
      <button class="button identityActions" button-type="secondary" v-if="menuMode.update == true"
        @click="upt_ident()"><span v-if="waiting_for_api_response" class="loading_spinner center"
          style="--color : white; position: relative"></span>
        <a v-else>{{ $t("Update") }}</a>
      </button>
    </div>
  </div>
</template>

<script>
//NPM
import axios from "axios"
// CUSTOMS LIBS
import file_api from "../../../../api/file";
import { displayDate } from "../../../../lib/date-time-duration.js";
import { jsonToFormData } from "../../../../lib/jsonToFormData";
// COMPONENTS
import IdentityContent from "./identityContent/identityContent.vue"
//STORE
import store from "../../../../store/index";
import Vuex from "vuex"

export default {
  name: "IdentityModifier",
  data() {
    return {
      local_current_identity: {
        _id: null,
        ident_name: "",
        ident_colors: [],
        ident_logo: null,
        ident_watermark: null,
        ident_baseline: "",
        ident_font: null,
        ident_intro: null,
        ident_title: null,
        ident_outro: null,
      },
      show_animation_catalog: false,
      animation_default_feature_value: {
        colors: [],
        font: {},
        logo: "",
      },

      uploaded_logo_file: null,
      waiting_for_api_response: false
    };
  },
  store: store,
  components: {

    IdentityContent
  },
  emits: ["show_animation_catalog", "finished"],
  props: [
    "current_identity",
    "menuMode",
    "usr_id",
    "identityModifier_style",
    "newAnimationIdFromCatalog",
    "backButton"
  ],
  computed: {

    addButtonActive() {
      var emptyField = false;
      if (
        this.local_current_identity.uploaded_logo_file == null ||
        this.local_current_identity.uploaded_logo_file == undefined
      ) {
        emptyField = true;
      } else if (
        this.current_identity.ident_font == null ||
        this.current_identity.ident_font == undefined ||
        this.current_identity.ident_font == ""
      ) {
        emptyField = true;
      } else if (this.current_identity.ident_colors.length != 3) {
        emptyField = true;
      }

      if (emptyField == false) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {


    //Setting up current_identity props as local data (to allow modification)
    if (this.current_identity != undefined && this.current_identity != null) {
      this.local_current_identity = this.current_identity;
    }

    //SETTINGS UP INPUT VALUE
    this.$refs.ident_name.value = this.local_current_identity.ident_name;

    //VERIFY IF LOGO EXIST
    file_api
      .get(this.local_current_identity.ident_logo)
      .catch(() => {
        /*  console.warn(
            "Impossible to retrieve logo from api. Setting it to null"
          );*/

      });


  },
  watch: {
    identityModifier_style(style) {
      if (style) {
        this.$refs.identityModifier.removeAttr("style").css(style);
      }
    },
    newAnimationIdFromCatalog(data) {
      if (data && data.anim_id && data.animation_ref) {
        this.local_current_identity[data.animation_ref] = data.anim_id;
        this.upt_ident();
      }
    },
  },
  methods: {
    ...Vuex.mapActions(["complete_onboarding_task"]),
    displayDate,
    //Update animation default feature and font name (was trigger at mounting and in watch of this.current_identity)

    nameChanged(evt) {
      this.local_current_identity.ident_name = evt.target.value;
    },


    //SAVE NEW IDENTITY
    add_ident() {

      //Set props usr_id to identity
      this.local_current_identity.usr_id = this.usr_id;

      //IF NO IDENT NAME : CREATE IT
      if (
        this.local_current_identity.ident_name == null ||
        this.local_current_identity.ident_name == undefined ||
        this.local_current_identity.ident_name == ""
      ) {
        this.local_current_identity.ident_name = `${this.$t('IdentityChoice.defaultName')} ${displayDate(
          new Date()
        )}`;
        this.$refs.ident_name.value = this.local_current_identity.ident_name;
      }

      //REQUIRE FIELD VERIFICATION
      if (
        this.local_current_identity.uploaded_logo_file == null ||
        this.local_current_identity.uploaded_logo_file == undefined
      ) {
        this.Toaster.error(this.$t('IdentityChoice.error.logo'));
      } else if (
        this.local_current_identity.ident_font == null ||
        this.local_current_identity.ident_font == undefined ||
        this.local_current_identity.ident_font == ""
      ) {
        this.Toaster.error(this.$t('IdentityChoice.error.font'));
      } else if (this.local_current_identity.ident_colors.length != 3) {
        this.Toaster.error(this.$t('IdentityChoice.error.colors'));
      } else if (
        this.local_current_identity.usr_id == undefined ||
        this.local_current_identity.usr_id == null ||
        this.local_current_identity.usr_id == ""
      ) {
        console.error("No usr_id");
      } else {
        this.waiting_for_api_response = true
        var entity = {
          ...this.current_identity,
          usr_id: this.usr_id,
        };
        delete entity.media
        delete entity.uploaded_logo_file
        delete entity.uploaded_watermark_file

        let formData = jsonToFormData(entity)
        formData.append("logo", this.local_current_identity.uploaded_logo_file)
        formData.append("watermark", this.local_current_identity.uploaded_watermark_file)
        console.log(formData)
        axios.post('/identities', formData)
          .then((res) => {

            this.waiting_for_api_response = false

            //COMPLETE ONBOARDING TASK
            this.complete_onboarding_task("create_visual_identity")

            //Send identity data to parent component
            this.$emit("finished", res.data);
          })
          .catch((err) => {
            this.waiting_for_api_response = false
            console.error(err);
            this.Toaster.error(this.$t('Toaster.error.add'));
          })
      }
    },

    //UPDATE IDENTITY
    upt_ident() {
      if (
        this.local_current_identity.ident_name == null ||
        this.local_current_identity.ident_name == undefined ||
        this.local_current_identity.ident_name == ""
      ) {
        this.local_current_identity.ident_name = `${this.$t('IdentityChoice.defaultName')} ${displayDate(
          new Date()
        )}`;
        this.$refs.ident_name.value = this.local_current_identity.ident_name;
      }
      this.waiting_for_api_response = true
      if (typeof this.local_current_identity.ident_font == 'object') {
        this.local_current_identity.ident_font = this.local_current_identity.ident_font._id
      }
      var entity = {
        ...this.local_current_identity,
        usr_id: this.usr_id,
      };


      delete entity.media
      delete entity.uploaded_logo_file
      delete entity.uploaded_watermark_file
      let formData = jsonToFormData(entity)
      formData.append("logo", this.local_current_identity.uploaded_logo_file)
      formData.append("watermark", this.local_current_identity.uploaded_watermark_file)

      axios.put('/identities/' + this.local_current_identity._id, formData)
        .then((res) => {
          this.waiting_for_api_response = false
          this.$emit("finished", res.data);
        })
        .catch(() => {
          this.waiting_for_api_response = false
          this.Toaster.error(this.$t('Toaster.error.add'));
        })

    },

    back() {
      this.waiting_for_api_response = false
      this.$emit("finished");
    },
  },
};
</script>


<style src="./style.css" scoped></style>