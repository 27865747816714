<template>
  <div :class="{
    panel: isApanel, notAPanel: !isApanel,

  }" panel-position="0">
    <div style="height: 100%; overflow: auto; ">
      <!-- TITLE (ARIAN) -->
      <p class="navigation_title" v-if="hide_title != true">
        <a v-if="back_button_title == true" @click="cancel">Retour</a>
        <a v-else @click="cancel">Tableau de bord</a>
        <a> / </a>
        <a> Charte graphique </a>
      </p>



      <!-- IDENTITY SELECTION -->
      <div class="cardListContainer center" v-if="menuMode.choice == true">
        <div id="new_identity" class="card little-card pointer" @click="setMenuMode('add')">
          <img src="../../../assets/add.svg" class="little-card-selection" />
          <p class="card-title little-card-title" style="margin-top: auto; margin-bottom: auto;">
            {{ $t('IdentityChoice.new') }}
          </p>
        </div>

        <div v-for="(ident, ident_index) in identities" :key="ident" :index="ident_index" class="card little-card"
          @click="choose_identity(ident._id)">
          <img v-if="identity != undefined && identity._id == ident._id" src="../../../assets/checked_white.png"
            class="little-card-check little-card-selection" />
          <img v-else-if="isChoosable == true" class="little-card-selection" @click.stop="choose_identity(ident._id)"
            src="../../../assets/Ellipse.png" />
          <div class="little-card-colors">
            <div :style="{ 'background-color': ident.ident_colors[0] }"></div>
            <div :style="{ 'background-color': ident.ident_colors[1] }"></div>
            <div :style="{ 'background-color': ident.ident_colors[2] }"></div>
          </div>
          <div class="little-card-body">
            <img :src="ident.ident_logo_url" class="little-card-image" />
            <div class="card-title little-card-title" :class="{ pointer: project_ids != 0 }">
              <p style="">
                {{ ident.ident_name }}
              </p>
            </div>

          </div>
          <div style="
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                margin-left: 8px;
    margin-right: 8px;
              ">
            <img v-if="ident.usr_id != undefined" class="little-card-icon" src="../../../assets/pen.svg"
              @click.stop="modify_ident(ident._id)" />
            <img v-if="ident.usr_id != undefined" class="little-card-icon" src="../../../assets/trash.png"
              @click.stop="remove_ident(ident._id)" />
          </div>
        </div>

      </div>

      <!-- IDENTITY CREATION / UPDATE-->
      <IdentityModifier v-if="menuMode.add == true || menuMode.update == true" ref="modifyPanel"
        :class="{ modifications: true, center: true, fullWidth: fullWidth }" :current_identity="current_identity"
        :menuMode="menuMode" :usr_id="usr_id" :style="identityModifier_style"
        @show_animation_catalog="handle_event_show_animation_catalog"
        :newAnimationIdFromCatalog="newAnimationIdFromCatalog" @finished="identityModifiderFinished"
        :backButton="identities.length != 0" />
    </div>
  </div>
</template>

<script>
// LIBS
import axios from "axios";
import $ from "jquery";

// CUSTOMS LIBS
import collect_api from "../../../api/collect";

//INTERNALS COMPONENT
import IdentityModifier from "./IdentityModifier/IdentityModifier";

//STORE
import Vuex from "vuex";
import store from "./../../../store/index";

export default {
  name: "IdentityChoice",
  props: {
    identity: {},
    template_identity: {},
    project_ids: { default: [] },
    usr_id: {},
    hide_title: {
      default: false,
    },
    back_button_title: {},
    isApanel: {
      default: true,
    },
    isChoosable: {
      default: false,
    },
    mode: {
      default: null
    }
  },
  store: store,
  components: {
    IdentityModifier,
  },
  emits: ["finished", "changeMode"],

  data: function () {
    return {
      current_identity: {
        _id: null,
        ident_name: "",
        ident_colors: [],
        ident_logo: null,
        ident_baseline: "",
        ident_font: null,
      },

      identities: [],
      menuMode: {
        choice: true,
        add: false,
        update: false,
      },

      addButtonEnable: false,
      show_animation_catalog: false,
      identityModifier_style: undefined,
      newAnimationIdFromCatalog: undefined,
    };
  },
  computed: {
    navigationTabIndex() {
      switch (this.setMenuMode()) {
        case "choice":
          return 0;
        case "add":
          return 1;
        case "update":
          return 1;
        default:
          return undefined;
      }
    },

    fullWidth() {
      var panel_pos = 100;
      if ($(".panel .identity_pannel").attr("panel-position") != undefined) {
        panel_pos = Number($(".panel .identity_pannel").attr("panel-position"));
      }

      if (panel_pos <= 50) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    // Set click location to app.vue
    /*  setTimeout(() => {
        emitter.emit("click_location", "panel1");
      }, 500);*/
    if (this.identity != undefined && this.identity._id != undefined) {
      this.current_identity = this.identity;
    }

    this.fetch_identities().then(() => {
      if (this.template_identity != undefined) {
        this.identities.unshift(this.template_identity)
      }
    })

    if (this.mode != null) {
      this.setMenuMode(this.mode)
    }
  },
  methods: {
    ...Vuex.mapActions(["putProjectData", "unsetField_project"]),

    //FETCH IDENTITY LIST
    fetch_identities() {
      return collect_api
        .get("/identities", { usr_id: this.usr_id, media: true })
        .then((idents) => {
          this.identities = idents;
          if (this.identities.length == 0) {
            this.setMenuMode("add");
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },

    modify_ident(id) {
      this.current_identity = this.identities.filter((identity) => {
        if (identity._id == id) {
          return true;
        } else {
          return false;
        }
      })[0];

      this.setMenuMode("update");
    },

    remove_ident(id) {
      axios.delete("/identities/" + id).then(() => {
        this.identities = this.identities.filter((obj) => obj._id != id);
        this.unsetField_project({ field: "ident_id", value: id });
        this.$nextTick(() => {
          if (this.identities.length == 0) {
            this.setMenuMode("add")
          }
        })
      });
      if (id == this.identity._id) {
        this.$emit("finished", undefined)
      }

    },

    cancel() {
      this.$emit("finished");
    },

    setMenuMode(mode) {
      if (mode != undefined) {
        let changeMade = true
        switch (mode) {
          case "choice":
            this.reset_form();
            this.menuMode.choice = true;
            this.menuMode.add = false;
            this.menuMode.update = false;
            this.fetch_identities();
            break;

          case "add":
            axios.post('/identities?testPermit=true')
              .then(() => {
                this.reset_form();
                this.menuMode.choice = false;
                this.menuMode.add = true;
                this.menuMode.update = false;
              }).catch(() => {
                this.changeMade = false
                this.Toaster.prompt({
                  title: this.$t('IdentityChoice.limitReached.title'),
                  message: this.$t('IdentityChoice.limitReached.message'),
                  validate: this.$t('Upgrade'),
                  cancel: this.$t('Back'),
                }).then((response) => {
                  if (response == "validate") {
                    this.$router.push("/profile/subscriptions");
                  }

                })
              })

            break;

          case "update":
            this.menuMode.choice = false;
            this.menuMode.add = false;
            this.menuMode.update = true;
            break;
        }
        if (this.changeMade) {
          this.$emit("changeMode", mode)
        }

      }

      if (this.changeMade) {
        if (this.menuMode.choice == true) {
          return "choice";
        } else if (this.menuMode.add == true) {
          return "add";
        } else if (this.menuMode.update == true) {
          return "update";
        }
      }

    },

    reset_form() {
      this.current_identity = {
        _id: null,
        ident_name: "",
        ident_colors: [],
        ident_logo: null,
        ident_baseline: "",
        ident_font: null,
      };
      this.uploaded_logo_file = undefined;
    },

    choose_identity(id) {
      if (this.project_ids.length == 0) {
        return
      }
      var index = this.identities.findIndex((obj) => {
        return obj._id == id;
      });


      this.current_identity = this.identities[index];
      this.$emit("finished", this.identities[index]);


    },

    handle_event_show_animation_catalog(data) {
      this.animation_default_feature_value =
        data.animation_default_feature_value;
      this.animation_modifier_ref = data.animation_ref;

      //When Animation modification appear, set modify panel to width 40%

      var style = {
        width: "40%",
      };

      //If panel-position of identity pannel is less than 50 (fullWidth == true), shift to left the pannel content
      if (this.fullWidth) {
        style["margin-left"] = "5%";
      } else {
        style.transform = "translateX(-20%)";
      }
      this.identityModifier_style = style;
      this.show_animation_catalog = true;
    },

    identityModifiderFinished(identity_data) {
      if (identity_data != undefined) {
        //SAVE NEW ENTITY OF IDENTITY MODIFICATION TO ENTITIES LIST
        var index = this.identities.findIndex((obj) => {
          return obj._id == identity_data._id;
        });

        if (index != -1) {
          this.identities[index] = identity_data;
        } else {
          this.identities.push(identity_data);
        }

        this.choose_identity(identity_data._id);
      }
      this.$nextTick(() => {
        this.setMenuMode("choice");
      });
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped></style>

<style scoped>
.pointer {
  cursor: pointer;
}

.card-title {
  margin-top: 10px;

  width: 100%;
}

.little-card-body {
  width: 65%;
  text-align: left;
  padding-left: 16px;
}

.little-card-check {
  background-color: #48d597;
  border-radius: var(--border_radius);
}

.little-card-selection {
  height: 22px;
  width: 22px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
}

.delete {
  margin: 0;
  position: absolute;
  right: 14px;
  top: 8px;
  width: 20px;

  cursor: pointer;
}

.little-card {
  display: inline-flex;
  height: 80px;
  width: 280px;
  width: 45%;
  max-width: 350px;
  margin: 15px;
  justify-content: space-between;
}

.little-card-colors {
  display: flex;
  flex-direction: column;
  width: 10%;
}

.little-card-colors>div {
  height: 33.3%;

  background-color: red;
}

.little-card-title {
  text-align: left;
  margin-top: 0px;
  display: table;
  margin-left: 0px
}

.little-card-title>p {
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;

  vertical-align: middle;
  display: table-cell;
}

.little-card-image {
  height: 30%;
  padding-top: 8%;

}

.little-card-icon {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.edition {
  height: 100%;
}
</style>
